import { useMemo, useCallback, Fragment } from 'react'
import { Rate } from 'antd'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'

import { Button, Typography } from 'common/widgets'
import CollapsibleParagraph from './CollapsibleParagraph'
import { useTranslations } from 'common/language'
import formatPrice from 'common/utils/formatPrice'
import classNames from './styles.module.scss'


ProductReviews.propTypes = {
  reviewCount: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  serviceReviews: ResourceType.isRequired,
}

export default function ProductReviews({ serviceReviews, reviewCount, rating }) {
  const { gettext, ngettext } = useTranslations()
  const isMoreEnabled = useMemo(() => get(serviceReviews, 'data.results', []).length < get(serviceReviews, 'data.count'), [serviceReviews?.data])
  const getAdditionalReviews = useCallback(() => {
    serviceReviews.fetch({ offset: (serviceReviews?.filters?.offset || 0) + 3, limit: 3 })
  }, [serviceReviews?.filters?.offset])
  return (
    <div className={classNames.wrapper}>
      {!isEmpty(serviceReviews.data?.results) ? (
        <Fragment>
          <div>
            <span className={classNames.score}>
              {rating} / 5
            </span>
            <Rate disabled allowHalf defaultValue={rating} className={cx(classNames.star, classNames.starColor)} />
            <span className={classNames.count}>
              {formatPrice(reviewCount)} {ngettext('Review', 'Reviews', reviewCount)}
            </span>
          </div>
          <div>
            {serviceReviews.data?.results?.map((item, index) => (
              <div key={index} className={classNames.reviewCard}>
                <div>
                  {item.author_display_name || gettext('Anonymous')}
                </div>
                <div>
                  <Rate disabled allowHalf defaultValue={item.rating} className={cx(classNames.rating, classNames.starColor)} />
                  <span>{item.period}</span>
                </div>
                {item.text && <CollapsibleParagraph className={cx(classNames.reviewText, classNames.wrap)} text={item.text} />}
                {item.answer_text && <blockquote className={classNames.answerText}>
                  <p>
                    {gettext('Answer from Boa Lingua')}
                  </p>
                  <CollapsibleParagraph text={item.answer_text} className={classNames.wrap} />
                </blockquote>}
              </div>
            ))}
          </div>
          {isMoreEnabled && (
            <div className={classNames.showMore}>
              <Button
                onClick={getAdditionalReviews}
                className={classNames.button}
                isLoading={serviceReviews?.isLoading}
              >
                {gettext('more')}
              </Button>
            </div>)}
        </Fragment>
      ) : (
        <Typography
          variant="h4"
          align="center"
          className={classNames.emptyText}
        >
          {gettext('There is no review available yet.')}
        </Typography>
      )}
    </div>
  )
}
