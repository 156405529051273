import PropTypes from 'prop-types'
import { Progress, Row, Col } from 'antd'
import cx from 'classnames'

import classNames from '../styles.module.scss'
import { Typography } from 'common/widgets'


SchoolRating.propTypes = {
  ratings: PropTypes.array,
  large: PropTypes.bool,
}

SchoolRating.defaultProps = {
  ratings: [],
  large: false,
}

export default function SchoolRating({ ratings, large }) {
  return (
    <div>
      {ratings.map(item => (
        <Row className={classNames.raitingTable} key={item.text}>
          <Col className={cx(classNames.raitingCol, classNames.raitingLabel, large && classNames.large)}>
            <Typography color="light-black" variant={large ? 'gray' : 'small-gray'}>{item.text}</Typography>
          </Col>
          <Col className={cx(classNames.raitingCol, classNames.raitingProgress, large && classNames.large)}>
            <Progress percent={item.value * 20} showInfo={false} strokeColor="#333333" />
          </Col>
          <Col className={cx(classNames.raitingCol, classNames.raitingValue, large && classNames.large)}>{item.value ? item.value.toFixed(1) : ''}</Col>
        </Row>
      ))}
    </div>
  )
}
