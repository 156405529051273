import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


CollapsibleParagraph.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

CollapsibleParagraph.defaultProps = {
  className: '',
}

export default function CollapsibleParagraph({ text, className }) {
  const { gettext } = useTranslations()
  const isDefaultCollapsed = text.length > 200
  const [isCollapsed, setCollapsed] = useState(isDefaultCollapsed)
  const dynamicText = isCollapsed ? text.substring(0, 200) : text
  const toggleCollapse = useCallback(() => setCollapsed(isCollapsed => !isCollapsed), [])
  return (
    <p className={className}>
      {dynamicText}
      {dynamicText && isDefaultCollapsed && (
        <button
          type="button"
          className={classNames.toggleReview}
          onClick={toggleCollapse}
        >
          {'...'}{isCollapsed ? gettext('Learn more') : gettext('Hide')}
        </button>)}
    </p>
  )
}
