/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import noop from 'lodash/noop'
import cx from 'classnames'

import { addFixedToBody, moveBackAfterClose } from 'common/utils/modalScroll'
import classNames from './styles.module.scss'


ModalWrapper.propTypes = {
  modalClassName: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  mask: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  component: PropTypes.any.isRequired,
  maskClosable: PropTypes.bool,
  width: PropTypes.number,
  closeIcon: PropTypes.node,
  closable: PropTypes.bool,
  withTrigger: PropTypes.bool,
  isFromSide: PropTypes.bool,
  forceRender: PropTypes.bool,
}

ModalWrapper.defaultProps = {
  title: '',
  modalClassName: undefined,
  mask: true,
  maskClosable: true,
  width: 792,
  closeIcon: undefined,
  closable: true,
  withTrigger: false,
  isFromSide: false,
  forceRender: false,
  onHide: noop,
}

export default function ModalWrapper(props) {
  const {
    title,
    show,
    onHide,
    component: ModalComponent,
    modalClassName,
    mask,
    maskClosable,
    width,
    closeIcon,
    closable,
    withTrigger,
    isFromSide,
    forceRender,
  } = props
  const icon = closeIcon !== undefined ? closeIcon : (
    <div className="close-icon-wrapper">
      <FontAwesomeIcon icon="times" />
      <FontAwesomeIcon icon="arrow-left" />
    </div>
  )
  useEffect(() => {
    if(show && !withTrigger) {
      addFixedToBody()

      return moveBackAfterClose
    }
  }, [show, withTrigger])
  return (
    <Modal
      visible={show}
      onHide={onHide}
      className={cx(modalClassName, isFromSide && classNames.fromSide)}
      onCancel={onHide}
      title={title}
      footer={null}
      destroyOnClose
      mask={mask}
      keyboard={false}
      maskClosable={maskClosable}
      getContainer="#__next"
      width={width}
      closeIcon={icon}
      closable={closable}
      forceRender={forceRender}
    >
      <main
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <ModalComponent {...props} />
      </main>
    </Modal>
  )
}
