import { connect } from 'react-redux'
import get from 'lodash/get'

import ServiceReviewBadgeView from './ServiceReviewBadgeView'


export default connect(
  (state) => ({
    rating: get(state, 'cmsInfo.data.service_recommendation.rating', 0),
    percentage: get(state, 'cmsInfo.data.service_recommendation.percentage', 0),
    reviewCount: get(state, 'cmsInfo.data.service_recommendation.reviews_count', 0),
  })
)(ServiceReviewBadgeView)
