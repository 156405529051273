import { useMemo, useCallback, Fragment } from 'react'
import { Rate } from 'antd'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'

import { Container, CTALink, Button, Typography } from 'common/widgets'
import SchoolRating from 'components/schoolFinder/FinderList/widgets/SchoolRating'
import CollapsibleParagraph from './CollapsibleParagraph'
import { useTranslations } from 'common/language'
import formatPrice from 'common/utils/formatPrice'
import classNames from './styles.module.scss'


ProductReviews.propTypes = {
  data: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    parent_id: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    reviews_count: PropTypes.number.isRequired,
    scores: PropTypes.object,
  }).isRequired,
  productReviews: ResourceType.isRequired,
}

ProductReviews.defaultProps = {
  data: {
    scores: {},
  },
}

export default function ProductReviews({ data, productReviews }) {
  const { gettext, ngettext } = useTranslations()
  const textLabels = {
    course_quality: gettext('Course quality'),
    leisure_program: gettext('Leisure program'),
    location: gettext('Location'),
    infrastructure: gettext('Infrastructure'),
    atmosphere: gettext('Atmosphere'),
  }
  const isMoreEnabled = useMemo(() => get(productReviews, 'data.results', []).length < get(productReviews, 'data.count'), [productReviews?.data])
  const getAdditionalExperiences = useCallback(() => {
    productReviews.fetch({ kind: data.kind, parent_id: data.parent_id, offset: (productReviews?.filters?.offset || 0) + 3, limit: 3 })
  }, [productReviews?.filters?.offset, data.kind, data.parent_id])
  const scores = Object.entries(data.scores || {}).map(([key, value]) => ({ text: textLabels[key], value }))

  return (
    <div className={classNames.wrapper}>
      <Container size="xl">
        {!isEmpty(productReviews.data?.results) ? (
          <Fragment>
            <div>
              <span className={classNames.score}>
                {data.rating} / 5
              </span>
              <Rate disabled allowHalf defaultValue={data.rating} className={cx(classNames.star, classNames.starColor)} />
              <span className={classNames.count}>
                {formatPrice(data.reviews_count)} {ngettext('Review', 'Reviews', data.reviews_count)}
              </span>
            </div>
            {data.kind === 'school' && (
              <div className={classNames.schoolRatings}>
                <SchoolRating ratings={scores} large />
              </div>
            )}
            <div>
              {productReviews.data?.results?.map((item, index) => (
                <div key={index} className={classNames.reviewCard}>
                  <div>
                    <span>{item.author_display_name || gettext('Anonymous')} | {data.kind !== 'school' ? <CTALink cta={item.school_name} ctaURL={item.school_url} className={classNames.link} /> : item.school_name}, {item.destination_name}</span>
                  </div>
                  <div>
                    <Rate disabled allowHalf defaultValue={item.rating} className={cx(classNames.rating, classNames.starColor)} />
                    <span>{item.period}</span>
                  </div>
                  {item.text && <CollapsibleParagraph className={cx(classNames.reviewText, classNames.wrap)} text={item.text} />}
                  {item.answer_text && <blockquote className={classNames.answerText}>
                    <p>
                      {gettext('Answer from Boa Lingua')}
                    </p>
                    <CollapsibleParagraph text={item.answer_text} className={classNames.wrap} />
                  </blockquote>}
                </div>
              ))}
            </div>
            {isMoreEnabled && (
              <div className={classNames.showMore}>
                <Button
                  onClick={getAdditionalExperiences}
                  className={classNames.button}
                  isLoading={productReviews?.isLoading}
                >
                  {gettext('more')}
                </Button>
              </div>)}
          </Fragment>
        ) : (
          <Typography
            variant="h4"
            align="center"
            className={classNames.emptyText}
          >
            {gettext('There is no review available yet.')}
          </Typography>
        )}
      </Container>
    </div>
  )
}
