import { prefetchResources } from '@ds-frontend/resource'

import { Loading } from 'common/widgets'
import ServiceReviewsView from './ServiceReviewsView'


export default prefetchResources({
  endpoint: 'api/v1/feefo/service-reviews',
  namespace: 'serviceReviews',
  reducer: 'paginationList',
  queries: ['offset', 'limit'],
}, {
  defaultParams: { offset: 0, limit: 3 },
  Loader: Loading,
  destroyOnUnmount: false,
  refresh: false,
})(ServiceReviewsView)
